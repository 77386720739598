@use "mixins" as *;
.car__toolbar-icon {
    width: 1rem;
    height: 1rem;
    color: var(--color-icon);
    padding: .1rem;

    &.-button {
        cursor: pointer;
    }

    &.-button:hover {
        cursor: pointer;
        color: var(--color-text-quaternary);
    }
}

.body__toolbar-button:hover .car__toolbar-icon {
    color: var(--color-icon-focus);
}

.car__state-remove {
    padding: 0;

    &:hover {
        background-color: transparent;
    }
}

#cars-app {
  height: 100%;
}

.p-button-text.p-button-secondary:not(:disabled):hover {
  background: var(--color-button-bg-focus);
  border: none;
  color: var(--color-text-primary);
}

.p-togglebutton-checked > .p-togglebutton-content > .car__card-state.-box-shadow {
  box-shadow: var(--p-overlay-popover-shadow);
}
