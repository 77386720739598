@use "mixins" as *;
.homepage__main {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 3rem;
    align-items: center;
    gap: 8rem;
    text-align: center;
    overflow: hidden;
    padding-bottom: 5rem;
}

.homepage__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    padding-top: 4rem;
    padding-inline: 1rem;
}

.homepage__screen-container {
    position: relative;
    contain: strict;
    width: 100%;
    height: 1000px;
    perspective: 4000px;
    perspective-origin: 100% 0;
    transform-style: preserve-3d;
    overflow: hidden;
    margin-top: -8rem;
    display: flex;
    justify-content: center;

    &.-staff-list {
        height: 40rem;
    }
}

.homepage__screen {
    display: flex;
    flex-direction: row;
    position: absolute;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 1%, var(--content-bg--color) 12%);
    box-shadow: -20px 20px 30px rgba(0, 0, 0, 0.5);
    border: 1px solid var(--color-homepage-border);
    border-radius: var(--radius);
    width: 1600px;
    height: 1000px;
    margin: 280px auto auto;

    &.-transform {
        transform: translateX(2%) scale(1.2) rotateX(47deg) rotateY(31deg) rotate(324deg);
        transform-origin: top left;
        backface-visibility: hidden;
    }

    &.-cars-list {
        flex-direction: column;
        pointer-events: none;
        height: 480px;
        overflow: hidden;
    }

    &.-staff-list {
        width: 75%;
        max-width: 1600px;
        height: 80%;
        margin-top: 100px;
        border: none;
        box-shadow: none;
        background: linear-gradient(to top left, rgba(0, 0, 0, 0) 1%, var(--content-bg--color) 22%);
        overflow: hidden;
    }
}

.homepage__screen-shadow {
    position: absolute;
    background: linear-gradient(to left, var(--content-bg--color) 7%, rgba(0, 0, 0, 0) 20%);
    width: 1700px;
    height: 1200px;
    margin: 280px auto auto;
    transform: translateX(2%) scale(1.2) rotateX(47deg) rotateY(31deg) rotate(324deg);
    transform-origin: top left;
    backface-visibility: hidden;
}

.homepage__screen-shadow-2 {
    position: absolute;
    background: linear-gradient(to top, var(--content-bg--color) 20%, rgba(0, 0, 0, 0) 60%);
    width: 100%;
    height: 250px;
    bottom: 0;
}

.body__container.-homepage {
    margin-top: 10px;
    box-shadow: -20px 20px 30px rgba(0, 0, 0, 0.5);
}

.homepage__screen-menu {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    flex-direction: column;
    width: 15rem;
    height: 100%;
    padding-left: .6rem;
    gap: 1rem;
    padding-top: .6rem;
}

.menu__section.-homepage:hover svg {
    color: var(--color-icon);
}

.menu__section.-focus.-homepage:hover svg {
    color: var(--color-icon-focus);
}

.menu__section.-focus.-homepage:hover {
    background-color: var(--color-button-bg-focus);
    opacity: 1;
}

.menu__section.-homepage:hover {
    background-color: transparent;
    color: var(--color-text-primary);
    opacity: .9;
    cursor: auto;
}

.homepage__block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    gap: 2rem;
    max-width: 85rem;

    &.-reduce-top-margin {
        margin-top: -4rem;
    }

    &.-reduce-top-margin-hard {
        margin-top: -12rem;
    }

    &.-bottom {
        justify-content: space-between;
    }

    &.-right {
        justify-content: flex-end;
        align-items: center;
        width: 25%;
    }
}

.homepage__column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    gap: 2rem;

    &.-ressources {
        width: 30%;
    }

    &.-cars-list {
        width: 25%;
        gap: .4rem;
    }

    &.-no-height {
        height: auto;
    }
}

.homepage__delimiter {
    background-color: var(--color-homepage-border);
    width: 1px;
}

.homepage__3D-blocks {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 8rem;
    justify-content: center;
}

.homepage__3D-block {
    --x: calc(var(--base-x) + 9.5%);
    --y: -15%;
    --z: calc(-1 * var(--base-z));
    position: absolute;
    transform: skewY(-4deg) rotateX(-14deg) rotateY(20deg) translateZ(var(--z)) translateX(var(--x)) translateY(var(--y));
    transform-style: preserve-3d;
    transition: .32s var(--ease-out-quad);
    transition-property: transform;
    width: 500px;
    height: 200px;
    background: linear-gradient(to right, var(--content-bg--color-homepage-light), transparent);

    &.-middle {
        --x: var(--base-x);
        --z: 0;
    }

    &.-top {
        --x: calc(var(--base-x) - 9.5%);
        --z: var(--base-z);
    }

    &:hover {
        --y: -25%;
    }
}

.homepage__sample-button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 1rem;
    gap: .8rem;
    flex-direction: column;
    width: 600px;
    height: 150px;
    border-radius: var(--radius);
    opacity: .2;
    transition: .32s var(--ease-out-quad);
    transition-property: opacity;

    &.-top {
        opacity: .8;
    }
}

.homepage__3D-block:hover .homepage__sample-button {
    opacity: 1;
}

.homepage__3D-block:hover .homepage__sample-button.-color-finished .homepage__text.-header {
    color: var(--car-state-finished);
}

.homepage__3D-block:hover .homepage__sample-button.-color-finished path.change-hover {
    stroke: var(--car-state-finished);
}

.homepage__3D-block:hover .homepage__sample-button.-color-delivered .homepage__text.-header {
    color: var(--car-state-delivered);
}

.homepage__3D-block:hover .homepage__sample-button.-color-delivered span {
    color: var(--car-state-delivered);
}

.car__card-state {
    background-color: var(--color-icon);
}

.homepage__3D-block:hover .homepage__sample-button.-color-delivered .car__card-state {
    background-color: var(--car-state-delivered);
}

.homepage__sample-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.homepage__sample-icon {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-icon);

    &.-color-coming {
        color: var(--car-state-coming);
    }
}

.homepage__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    border: .4px solid var(--color-border-tertiary);
    border-radius: var(--radius);
    background-color: var(--content-bg--color-light);
    height: 1.8rem;
    gap: 0rem;

    &.-text {
        padding-left: .6rem;
        height: 2.2rem;
        border-color: transparent;
    }
}

.homepage__button-icon {
    display: flex;
    width: 14%;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.homepage__icon {
    height: 1rem;
    width: 1rem;
    color: var(--color-icon);
}

.homepage__day-container {
    display: flex;
    z-index: 998;
    border-radius: var(--radius);
    background: linear-gradient(to bottom right, var(--content-bg--color-homepage-light) 20%, transparent 70%);
    padding: 1rem;
}

.homepage__text {
    @include title;

    &.-title {
        font-size: 4rem;
        line-height: 4.5rem;
        background: linear-gradient(to right, var(--color-homepage-text-primary), var(--color-homepage-text-tertiary));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &.-small-title {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    &.-body {
        font-size: 1.5rem;
        line-height: 1.9rem;
        color: var(--color-homepage-text-tertiary);
    }

    &.-subtitle {
        font-size: 2rem;
        line-height: 2.1rem;
    }

    &.-subbody {
        @include body;
        color: var(--color-homepage-text-tertiary);
        text-align: center;
    }

    &.-color-coming {
        color: var(--car-state-coming);
    }

    &.-highlight {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .4rem;
        color: var(--color-homepage-text-primary);
    }

    &.-left {
        text-align: left;
    }
}

.car__toolbar-icon.-homepage {
    color: var(--color-icon-focus);
}

.homepage__halo {
    position: absolute;
    display: inline-block;
    background: radial-gradient(circle, var(--color-button-bg-shadow-homepage), var(--content-bg--color-homepage-lighter));
    border-radius: 50%;
    overflow: visible;
    filter: blur(70px);
    z-index: -1;
    width: 100%;
    left: 0;
    height: 20rem;
    top: -10rem;

    &.-calendar {
        top: 10rem;
        left: -10rem;
    }

    &.-cars-list {
        top: 22rem;
        left: -15rem;
    }

    &.-staff-list {
        top: 10rem;
        left: -5rem;
        height: 40rem;
    }
}

.homepage__image {
    border: .8px solid var(--color-homepage-border-header);
    border-radius: var(--radius);
    overflow: hidden;
    width: 100%;
    max-width: 80rem;

    &.-cars-in-charge {
        max-width: 60rem;
    }
}

.homepage__image img {
    width: 100%;
    height: auto;
    display: block;
}

.french-flag {
    width: 6.4rem;
}

.homepage__block.-ressources {
    gap: 8rem;
}

.homepage__image-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1rem;
    background-color: var(--content-bg--color-light);
    width: 27rem;
    height: 32rem;
    border-radius: var(--radius-max);
    transition: transform 0.3s ease;
}

.homepage__column:hover .homepage__image-container {
    transform: scale(1.1);
}

.homepage__column.-first:hover ~ .homepage__column.-second .homepage__image-container,
.homepage__column.-first:hover ~ .homepage__column.-third .homepage__image-container,
.homepage__column.-second:hover ~ .homepage__column.-third .homepage__image-container {
    transform: translateX(1rem);
}

.homepage__column:has(+ .homepage__column.-second:hover) .homepage__image-container {
    transform: translateX(-1rem);
}

.homepage__column:has(~ .homepage__column.-third:hover) .homepage__image-container {
    transform: translateX(-1rem);
}

.day__car-new-text {
    @include subbody;
    margin: 0;
    color: var(--color-text-secondary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.homepage__legals {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.homepage__legal {
    @include body;
    opacity: .9;

    &:hover {
        opacity: 1;
    }
}

#staff-list-app {
  width: 100%;
  height: 100%;
}

#ai-features-app {
  width: 70%;
  height: 100%;
  max-width: 85rem;
}

@media (max-width: $breakpoint-l) {
    .homepage__screen {
        width: 1400px;
    }

    .homepage__screen.-cars-list {
        width: 1200px;
    }
}

@media (max-width: $breakpoint-m) {
    .homepage__screen {
        margin-left: 400px;
    }

    .homepage__block {
        width: 100%;
        flex-direction: column;
        align-items: center;

        &.-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 80%;
        }
    }

    .homepage__screen.-cars-list {
        width: 1000px;
        margin-left: 0;
    }

    .homepage__column.-cars-list {
        width: 80%;
        text-align: left;
        align-items: center;
    }

    .homepage__column {
        width: 100%;
        align-items: center;
    }

    .homepage__3D-blocks {
        width: calc(100% - 30rem);
        overflow: hidden;
    }

    .homepage__sample-button {
        width: 400px;
    }

    .homepage__text.-ressources {
        margin-top: 15rem;
    }

    .homepage__text.-left {
        text-align: center;
    }
}

@media (max-width: $breakpoint-s) {
    .homepage__3D-blocks {
        width: calc(100% - 20rem);
    }

    .homepage__screen.-cars-list {
        width: 700px;
        margin-left: -100px;
    }
}

@media (max-width: $breakpoint-mobile) {
    .header {
        padding: 0rem 1rem;
    }

    .header__content {
        gap: .2rem;
    }

    .header__text {
        @include body;
        color: var(--color-homepage-text-secondary);

        &.-title {
            display: none;
        }

        &.-cap-height {
            padding: 0;
            width: 6rem;
            justify-content: center;
            align-items: center;
        }
    }

    .homepage__text {
        &.-title {
            font-size: 2.5rem;
            line-height: 2.9rem;
        }

        &.-body {
            font-size: 1.2rem;
            line-height: 1.5rem;
        }

        &.-subtitle {
            font-size: 1.5rem;
            line-height: 1.7rem;
        }

        &.-left {
            text-align: center;
        }
    }

    .homepage__3D-blocks {
        width: 100%;
        margin-right: 8rem;
    }

    .homepage__3D-block {
        width: 20rem;
        --base-x: 30%;
        --x: calc(var(--base-x) + 15%);

        &.-top {
            --x: calc(var(--base-x) - 15%);
        }
    }

    .homepage__sample-button {
        width: 17rem;
    }

    .day.-homepage {
        width: 15rem;
        max-width: 15rem;
    }
}
