@use "mixins" as *;
@mixin text-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    color: var(--color-text-primary);
    font-weight: 400;
    line-height: 1.5rem;
}

.about {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 90vh;
    padding: 4rem 2rem;
    overflow-y: auto;
    align-items: center;
}

.about__back-button {
    @include text-subtitle;
    position: fixed;
    color: var(--color-text-secondary);
    text-align: center;
    padding: 0.4rem 1rem;
    border-radius: var(--radius-max);
    gap: 0.4rem;
    margin-bottom: 2rem;
    width: fit-content;
    align-self: flex-start;

    &:hover {
        color: var(--color-icon-focus);
        background-color: var(--color-button-bg-focus);
    }
}

.about__icon {
    width: 2rem;
    height: 2rem;
    color: var(--color-icon);

    &.-rotate {
        transform: rotate(180deg);
    }

    &.-small {
        width: 1rem;
        height: 1rem;
    }
}

.about__back-button:hover .about__icon {
    color: var(--color-icon-focus);
}

.about__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 80%;
}

.about__title {
    @include title;
    font-size: 3.5rem;
    line-height: 3.5rem;
    color: var(--color-text-primary);
    text-align: center;
}

.about__2-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    margin-top: 2rem;
}

.about__persona {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.about__persona-details {
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: space-between;
}

.about__persona-header {
    display: flex;
    gap: 2rem;
}

.about__persona-image {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.about__persona-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about__persona-name {
    @include title;
    line-height: 1.5rem;
    color: var(--color-text-primary);
}

.about__persona-job {
    @include text-subtitle;
    color: var(--color-text-secondary);
}

.about__persona-links {
    display: flex;
    gap: .6rem;
}

.about__persona-link {
    padding: 0;

    &:hover {
        color: transparent;
    }
}

.about__persona-link img {
    width: 1.6rem;
    height: 1.6rem;
}

.about__persona-link:hover .about__icon.-linkedin {
    color: var(--color-linkedin);
}

.about__persona-link:hover .about__icon.-github {
    color: var(--color-github);
}

.about__persona-link:hover .about__icon.-instagram {
    color: var(--color-instagram);
}

ul {
    margin-block-start: 0;
    padding-inline-start: 0;
}

.about__text, .about__persona-text {
    @include text-subtitle;
    font-weight: 400;
    color: var(--color-text-primary);
    white-space: pre-wrap;
    text-align: justify;

    &.-li {
        text-align: left;
        white-space: normal;
    }
}

.about__link {
    color: var(--color-text-primary);
    display: inline-block;
    padding-inline: 0.2rem;
    text-decoration: underline !important;
}

@media (max-width: $breakpoint-m) {
    .about__container {
        width: 100%;
    }

    .about__title {
        @include title;
    }

    .about__text, .about__persona-text {
        @include body;
    }

    .about__persona-header {
        gap: .8rem;
    }

    .about__persona-image {
        width: 4rem;
        height: 4rem;
    }

    .about__persona-details {
        height: 4rem;
    }

    .about__persona-name {
        @include subtitle;
        line-height: 1.2rem;
    }

    .about__persona-job {
        @include body;
    }
}

@media (max-width: $breakpoint-s) {
    .about__2-column {
        grid-template-columns: 1fr;
    }
}

@media (max-width: $breakpoint-mobile) {
    .about__persona-details {
        max-width: 8.9rem;
    }
}
