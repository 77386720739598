@use "mixins" as *;
:root {
    --cars-width: 15rem;
    --section-height: 2.3rem;
}

.cars__modal.-replacements {
    max-height: 12rem;
    width: 33rem;
}

.cars__modal-car.-replacements {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    width: 31rem;
    background-color: var(--content-bg--color-light);
    border-radius: var(--radius);
    border: .4px solid var(--color-border-secondary);
    padding: .2rem .4rem;

    &:hover {
        border-color: var(--color-border-focus);
    }

    &.-hidden, &.-selected {
        display: none;
    }
}

.car__modal-details.-replacement-car {
    justify-content: flex-start;
    display: flex;
}

.-coming > .replacement-speed-dial > .replacement-speed-dial-button {
  color: var(--color-text-primary) !important;
}

.replacement > .p-dialog-header {
    align-items: flex-start !important;
    min-width: 30rem;
  }
