@use "mixins" as *;
.main-body {
    display: flex;
    width: calc(100% - 28px);
    height: calc(100% - 28px);
    background-color: var(--content-bg--color);
    margin: 14px;
    padding: 0px;
    overflow: hidden;
    font-family: "Inter", sans-serif;

    &.-home {
        flex-direction: column;
        overflow-y: auto;
        background-color: var(--content-bg--color);
        width: 100%;
        height: 100%;
        margin: 0;
    }
}
