@use "mixins" as *;
#insurances-app {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.p-select.insurance-group-filter {
  transition: none !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 .4rem !important;
  border-radius: 0 !important;

  &:hover {
    background: var(--color-button-bg-focus) !important;
  }
}

.p-select.insurance-group-filter > .p-select-dropdown {
  display: none !important;
}

.p-select.insurance-group-filter > .p-select-label {
  padding: 0 !important;
}

.p-select.insurance-group-filter > .p-select-label.p-placeholder {
  color: var(--color-text-secondary) !important;
  display: flex;
  align-items: center;
}
